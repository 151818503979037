<template>
    <div class="probox">
        <div class="tubox" v-if="thumbnailUrl">
            <img :src="thumbnailUrl" class="imgar" alt="" />
        </div>
        <div class="kapian">
            <Card dis-hover class="dik" :class="[gonggao[index].type == 2 ? 'active' : '']"
                v-for="(item, index) in gonggao" :key="index">
                <div slot="title" class="tit">
                    <div class="tongz">{{ gonggao[index].plateName }}</div>
                    <div class="gd">
                        <div class="gg" @click="golist(gonggao[index].id,gonggao[index].plateName)">更多</div>
                        <img src="../../assets/img/index/Combined Shape@2x.png" class="imga" alt="" />
                    </div>
                </div>

                <div class="boxa" :class="[gonggao[index].type==2?'demo-split-paneq':'']"
                    v-show="modecon[index].length != 0">
                    <div class="contxx" v-for="(item1, lin) in modecon[index]" :key="lin">
                        <div class="flexbox">
                            <div class="dian"></div>
                                <div class="biao" @click=" yenei( item1.subjectTitle,item1.subjectResource, item1.subjectContent, item1.linkAddr, item1.fileUrl, item1.createTime, item1.subjectType,item1.videoUrl ,item1.fileName,item1.id) ">
                                    {{ item1.subjectTitle }}
                                </div>
                            <div class="time">{{ item1.createTime }}</div>
                        </div>
                        
                    </div>
                </div>
            </Card>
        </div>
    </div>
</template>
<script>
    import {incrReadingNum, querySubjectPlate, querySubjectPlateContent } from "../../woke/xinx";
    import { formatDate } from "../../commm/time";
    import Vue from 'vue';
    export default {
        data() {
            return {
                gonggao: [],
                id: "",
                num: 1,
                type: "",
                zid: "",
                modecon: [],
                thumbnailUrl:'',
            };
        },
        created() {
            this.querySubjectPlate();
        
            this.$nextTick(() => {
                // console.log(this.modecon, "....................................");
            });
        },
        methods: {
            //查询专题下的模板
            querySubjectPlate() {
                this.id = this.$route.query.id;
                this.thumbnailUrl = this.$route.query.img
                // console.log(this.imgUrl);
                console.log(this.id);
                querySubjectPlate(this.id).then((res) => {
                    // console.log(res, "查询专题下的模板");
                    this.gonggao = res.data.data;
                    console.log(this.gonggao, "一个排序");
                    //给数字this.gonggao.length个空对象
                    for (let i = 0; i < this.gonggao.length; i++) {
                        let a = {}
                        this.modecon.push(a)
                    }
                    // console.log(this.modecon, '2222222222222222222222222222222222');
                    this.gonggao.forEach((e, index) => {
                        // console.log(e.id);
                        querySubjectPlateContent({
                            id: e.id,
                            curPage: 1,
                            maxPage:  e.type == 1 ? '5':'10',
                        }).then((res) => {
                            // console.log(e.type,"条数");
                            // console.log(this.gonggao);
                            // console.log(e.id);
                            console.log(res, "查询专题下的模板的内容");
                            //给this.modecon下标为index赋个值
                            Vue.set(this.modecon, index, res.data.data.data);
                            // console.log(res.data.data.data);
                        });
                    });
                });
            },
          
            golist(id,plateName) {
                // console.log(id);
                this.$router.push({
                    name: "golist",
                    params: {
                        id: id,
                        plateName:plateName,
                    },
                });
            },
            yenei(subjectTitle, subjectResource,  subjectContent, linkAddr,  fileUrl, createTime, subjectType, videoUrl, fileName,id) {
                // console.log( subjectTitle, subjectResource, subjectContent,  linkAddr,  fileUrl,  createTime, subjectType, videoUrl, fileName );
                 incrReadingNum({
                        id:id,
                        type:2
                    }).then(res=>{
                        console.log(res);
                    })
                if (subjectType == 1) {
                    // console.log(11);
                    
                    this.$router.push({
                        name: "projecttwo",
                        params: {
                            tit: subjectTitle,
                            subjectResource: subjectResource,
                            subjectContent: subjectContent,
                            linkAddr: linkAddr,
                            fileUrl: fileUrl,
                            time: createTime,
                            videoUrl: videoUrl,
                            fileName: fileName
                        },
                    });
                } else if (subjectType == 2) {
                    window.open(linkAddr, "_blank");
                }
            },
          
        },
        filters: {
            /* 时间格式自定义 只需把字符串里面的改成自己所需的格式*/
            formatDate(time) {
                var date = new Date(time);
                return formatDate(date, "yyyy-MM-dd");
            },
        },
    };
</script>
<style lang="less" scoped>
.probox {
    width: 1200px;
    margin-top: 50px;
    margin-bottom: 30px;
    min-height: 600px;
}

.tubox {
    width: 1200px;
    height: 220px;
    margin-left: 360px;
   margin-bottom: 160px;
}

.imgar {
    width: 1200px;
    height: 340px;
    margin-top: 10px;
    
}

.kapian {
    width: 1220px;
    margin-left: 350px;
    display: flex;
    flex-flow: wrap;
}

.lianbox {
    width: 1200px;
    margin-left: 360px;
}


.dik {
    width: 49%;
    margin-bottom: 20px;
  
}
.boxa{
    width: 570px;
    height: 200px;
    margin-bottom: 20px;
    margin-left: 10px;
}
.active {
    width: 1205px;
    margin-bottom: 20px;
}

.flexbox {
    width: 570px;
    display: flex;
    justify-content: space-around;
    height: 40px;
    line-height: 40px;
    // margin-left: -10px;
}
.contxx{
    display: inline-block;
    width: 590px;
    height: 40px;
    line-height: 40px;
    // writing-mode: horizontal-tb;
}
/deep/.ivu-card-body{
    height: 210px;
}
.tit {
    display: flex;
    justify-content: space-between;
}

/deep/ .ivu-card-head {
    height: 50px;
}

.ivu-card {
    margin-left: 10px;
}

.tongz {
    width: 168px;
    height: 50px;
    background-image: url(../../assets/img/index/5.png);
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 18px;
    font-family: MicrosoftYaHei-Bold;
    line-height: 50px;
    color: #FFFFFF;
    text-align: center;   
    line-height: 50px;
    margin-left: -16px;
    margin-top: -14px;
}

.gg {
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #2c3b5b;
    line-height: 19px;
    letter-spacing: 1px;
    cursor: pointer;
}

.gd {
    width: 200px;
    display: flex;
    justify-content: right;
    margin-right: 20px;
}

.imga {
    width: 10px;
    height: 12px;
    margin-top: 4px;
}

.demo-split-paneq {
    width: 1200px;
    display: flex;
    flex-wrap: wrap;
    height: 210px;
    font-size: 0;
    // writing-mode: vertical-lr;
    margin-left: 10px;
}

.dian {
    width: 10px;
    height: 10px;
    background: #51acff;
    border-radius: 50%;
    margin-top: 7px;
}

.biao {
    width: 400px;
    height: 20px;
    font-size: 16px;
    font-family: MicrosoftYaHei;
    color: #4d4d4d;
    line-height: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    // margin-left: -20px;
    cursor: pointer;
}

.time {
    margin-right: 10px;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    color: #999999;
    line-height: 19px;
}
</style>