export function formatDate(date) {
    var time = String(date).replace(/-/g, "/"); //文本替换
    return format(time);
};




 
//转换成你想要的的格式
function format(d) {
    var date = new Date(d);
    var year = date.getFullYear();
    var mon = (date.getMonth()+1 < 10 ? '0'+(date.getMonth()+1) : date.getMonth()+1);
    var day = (date.getDate() < 10 ? '0'+(date.getDate()) : date.getDate());
    return  year + "/" + mon + "/" + day;
}
